import axios from 'axios';
import {checkIfCodeExist, saveCode, converterTP} from './thirdPartyMiddlewareService';


const { REACT_APP_THIRD_PARTY_API_URL } = process.env;
const { REACT_APP_THIRD_PARTY_API_KEY } = process.env;

const http = axios.create({
    baseURL: REACT_APP_THIRD_PARTY_API_URL,
    timeout: 10000000,
    headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
})

export const getBookies = () => {
    return http.get(`supported_bookies?api_key=${REACT_APP_THIRD_PARTY_API_KEY}`)
}

const thirdPartyConverter = (code, from, to) => {
    return http.get(`conversion?from=${from}&to=${to}&booking_code=${code}&api_key=${REACT_APP_THIRD_PARTY_API_KEY}`)
}

export const controlledConverter = async (code, from, to) => {
    let data = new FormData();
    data.append("code", code)
    data.append("bookiefrom", from)
    data.append("bookieto", to)

    let checker = await checkIfCodeExist(data);
    if(checker.data.isAvailable === true){
        return {
            code,
            from,
            to,
            result: checker.data.result,
            entries: checker.data.entries,
            convertedEntries: checker.data.convertedEntries,
            error: checker.data.error,
            initialOdds: checker.data.initialOdds,
            finalOdds: checker.data.finalOdds,
            games: []
        };
    }
    else{
        var cResult = await thirdPartyConverter(code, from, to);
        let result = cResult.data.data.conversion.destination_code
        let entries = cResult.data.data.conversion.dump.home_bookie_entries.summary.no_of_entries
        let convertedEntries = cResult.data.data.conversion.dump.destination_bookie_entries.summary.no_of_entries
        let error = "--"
        let initialOdds = cResult.data.data.conversion.dump.home_bookie_entries.summary.odds_value
        let finalOdds = cResult.data.data.conversion.dump.destination_bookie_entries.summary.odds_value
        let rawgames = cResult.data.data.conversion.dump.destination_bookie_entries.converted_booking.uniform_event
        let games = Object.entries(rawgames).map(e => e[1])

        let dts = new FormData();
        dts.append("code", code)
        dts.append("bookiefrom", from)
        dts.append("bookieto", to)
        dts.append("result", result)
        dts.append("entries", entries)
        dts.append("destentries", convertedEntries)
        dts.append("conversionError", error)
        dts.append("fromodds", initialOdds)
        dts.append("toodds", finalOdds)

        await saveCode(dts)

        return {
            code,
            from,
            to,
            result: result,
            entries: entries,
            convertedEntries: convertedEntries,
            error: error,
            initialOdds: initialOdds,
            finalOdds: finalOdds,
            games: games
        }
        
    }
}

export const controlledConverter2 = async (payload) => {
    

    let checker = await converterTP(payload);
    if(checker.data.isAvailable === true){
        return {
            code: payload.code,
            from: payload.bookiefrom,
            to: payload.bookieto,
            result: checker.data.result,
            entries: checker.data.entries,
            convertedEntries: checker.data.convertedEntries,
            error: checker.data.error,
            initialOdds: checker.data.initialOdds,
            finalOdds: checker.data.finalOdds,
            games: []
        };
    }
    else{
        if(checker.data.error === "---"){
            var cResult = checker.data.rawData;
            let result = cResult.data.conversion.destination_code
            let entries = cResult.data.conversion.dump.home_bookie_entries.summary.no_of_entries
            let convertedEntries = cResult.data.conversion.dump.destination_bookie_entries.summary.no_of_entries
            let error = "--"
            let initialOdds = cResult.data.conversion.dump.home_bookie_entries.summary.odds_value
            let finalOdds = cResult.data.conversion.dump.destination_bookie_entries.summary.odds_value
            let rawgames = cResult.data.conversion.dump.destination_bookie_entries.converted_booking.uniform_event
            let games = Object.entries(rawgames).map(e => e[1])
    
            return {
                code: payload.code,
                from: payload.bookiefrom,
                to: payload.bookieto,
                result: result,
                entries: entries,
                convertedEntries: convertedEntries,
                error: error,
                initialOdds: initialOdds,
                finalOdds: finalOdds,
                games: games
            }
        }
        else{
            return {}
        }
        
    }
}