import React from 'react';
import { useQuery } from '../Components/useQuery';
import { Helmet } from "react-helmet";
import Frame from "../Components/Frame";
import {Container, Row, Col, Spinner} from 'react-bootstrap';
import { useState } from '@hookstate/core';
import {resetPassword} from '../../services/authServices';
import { Formik } from 'formik';
import * as Yup from 'yup';
import store from '../../store/store';

const ResetPassword = () => {
    const query = useQuery()
    const [pwdConfirmation, setPwdConfirmation] = React.useState("")
    const [pwdConfirmationError, setPwdConfirmationError] = React.useState("")

    const {user} = useState(store)
    const {alertNotification} = useState(store)
    const {alertMessage} = useState(store)
    const {alertType} = useState(store)

    const initialValues = {
      email: "",
      token: query.get("token"),
      password: "",
    }

    const onSubmit = async (value) => {
      if(pwdConfirmation === value.password){
        value.password_confirmation = pwdConfirmation
        try{
          let res = await resetPassword(value)
          const status = res.status
          // const data = res.data
          if(status === 200){
            alertType.set("success")
            alertMessage.set("Password Reset Successful, You Can login with your new credential")
            alertNotification.set(true)
            setTimeout(() => {
                alertNotification.set(false)
            }, 1500)
          }
        }
        catch(err) {
          alertType.set("danger")
          alertMessage.set("Error")
          alertNotification.set(true)
          setTimeout(() => {
              alertNotification.set(false)
          }, 1500)
      }
      }
      else{
        alertType.set("danger")
          alertMessage.set("Password does not match")
          alertNotification.set(true)
          setTimeout(() => {
              alertNotification.set(false)
          }, 1500)
      }
    }

    const validationSchema = Yup.object({
      email: Yup.string().email("Invalid Email Format").required("Email is required"),
      password: Yup.string().required("Password is required"),
    })

  return (
    <Frame>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Reset Password | ConvertedCode</title>
      </Helmet>
      <Container fluid className="wrapper">
        <Row className="pt-5 ml-n5 mr-n4">
          <Col lg={12}>
            <div className="faq-wrapper">
                <span className="head">
                    Reset Password
                </span>
            </div>
          </Col>
          <Col lg={12}>
        <div className="reg-form-align mt-4">
                  <Formik
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                      validationSchema={validationSchema}
                  >
                      {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          isSubmitting,
                          handleBlur,
                          handleSubmit,
                          
                          /* and other goodies */
                      }) => (
                          <form onSubmit={handleSubmit}>
                              <div className="form-group">
                                  <input 
                                      className="form-control" 
                                      type="email"
                                      name="email"
                                      placeholder="Email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                  />
                                  <small className="form-text text-danger"> 
                                      {errors.email && touched.email && errors.email}
                                  </small>
                              </div>
                              <div className="form-group">
                                  <input 
                                      className="form-control" 
                                      type="password"
                                      name="password"
                                      placeholder="Password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                  />
                                  <small className="form-text text-danger"> 
                                      {errors.password && touched.password && errors.password}
                                  </small>
                              </div>
                              <div className="form-group">
                                  <input 
                                      className="form-control" 
                                      type="password"
                                      name="pwdConfirmation"
                                      placeholder="Confirm Password"
                                      onChange={(e) => setPwdConfirmation(e.target.value)}
                                      value={pwdConfirmation}
                                  />
                                  <small className="form-text text-danger"> 
                                      {pwdConfirmationError}
                                  </small>
                              </div>
                              <div className="form-btns">
                                  <button
                                      type="submit"
                                      disabled={isSubmitting}
                                      className="btn-green reg-btn"
                                  >
                                      {isSubmitting 
                                          ?
                                          <Spinner animation="border" size="sm" />
                                          :
                                          "Reset Password"
                                      }
                                  </button>
                              </div>
                          </form>
                          )}
                      </Formik>
                  </div>
              </Col>
          </Row>
      </Container>
      <Container fluid className="support-wrapper">
          <Row className="pt-5 ml-n5 mr-n4 pl-5 pr-5">
              <Col lg={12}>
              <div className="punters-tips-table ml-n2">
                  <h3 className="sub-title">Reset Password</h3>
                  <div className="contact">
                  <Formik
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                      validationSchema={validationSchema}
                  >
                      {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          isSubmitting,
                          handleBlur,
                          handleSubmit,
                          
                          /* and other goodies */
                      }) => (
                          <form onSubmit={handleSubmit}>
                              <div className="form-group">
                                  <input 
                                      className="form-control" 
                                      type="email"
                                      name="email"
                                      placeholder="Email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.email}
                                  />
                                  <small className="form-text text-danger"> 
                                      {errors.email && touched.email && errors.email}
                                  </small>
                              </div>
                              <div className="form-group">
                                  <input 
                                      className="form-control" 
                                      type="password"
                                      name="password"
                                      placeholder="Password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                  />
                                  <small className="form-text text-danger"> 
                                      {errors.password && touched.password && errors.password}
                                  </small>
                              </div>
                              <div className="form-group">
                                  <input 
                                      className="form-control" 
                                      type="password"
                                      name="pwdConfirmation"
                                      placeholder="Confirm Password"
                                      onChange={(e) => setPwdConfirmation(e.target.value)}
                                      value={pwdConfirmation}
                                  />
                                  <small className="form-text text-danger"> 
                                      {pwdConfirmationError}
                                  </small>
                              </div>
                              <div className="form-btns">
                                  <button
                                      type="submit"
                                      disabled={isSubmitting}
                                      className="btn-green reg-btn"
                                  >
                                      {isSubmitting 
                                          ?
                                          <Spinner animation="border" size="sm" />
                                          :
                                          "Reset Password"
                                      }
                                  </button>
                              </div>
                          </form>
                          )}
                      </Formik>
                  </div>
              </div>
              </Col>
          </Row>
      </Container>
    </Frame>
  )
}

export default ResetPassword