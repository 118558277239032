export const HamburgerBarsIcon = () => {
    return (
        <span>
            <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H19" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"/>
                <path d="M1 8H13" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"/>
                <path d="M1 15H16" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"/>
            </svg>
        </span>
    )
}

export const HomeIcon = () => {
    return (
        <svg width="33" height="31" viewBox="0 0 33 31" fill="none"
        xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7 13.2096C13.0024 9.16471 16.1905 6.20576 20.327 4.27631C20.5531 4.17094 20.9978 4.21433 21.173 4.373C22.1891 5.29155 23.1555 6.26526 24.2027 7.28298L23.4996 7.90899C23.0332 7.65549 22.6512 7.40447 22.2319 7.25695C22.1462 7.22658 21.7555 7.63999 21.7934 7.7528C21.9177 8.12778 22.1723 8.45875 22.4735 8.97009L22.2617 9.16595C21.7785 8.86968 21.45 8.59511 21.073 8.47859C20.9487 8.44016 20.5084 8.86597 20.5425 8.98683C20.6481 9.36429 20.9152 9.69712 21.1636 10.131C21.0674 10.1564 20.8531 10.2667 20.6438 10.2611C17.2277 10.1682 14.5073 11.4964 12.4123 14.262L10.7 13.2096Z" fill="#8B8787"/>
            <path d="M11.7012 14.8216C11.3223 16.4833 10.9683 18.0378 10.6161 19.583C10.4459 19.6681 10.2511 19.7745 10.0317 19.9022C9.83166 20.0187 9.63725 20.1427 9.44844 20.2741L9.36024 20.2121C9.3677 20.2499 9.37391 20.2865 9.38074 20.3212C9.22298 20.4315 9.06915 20.5464 8.91926 20.6658L8.81926 20.6149C8.83169 20.6509 8.84287 20.685 8.85405 20.7172C8.68262 20.8548 8.51679 20.9974 8.35717 21.143L8.31742 21.1226C8.31742 21.1387 8.32425 21.1542 8.32735 21.1697C6.76777 22.5903 5.71376 24.3115 5.22185 25.2114C3.42066 24.5687 1.72443 22.4533 1.89213 20.9967C3.37469 21.1914 5.07341 20.5846 7.66836 19.0177C11.0068 17.0033 10.5062 14.3029 10.5062 14.3029L11.7012 14.8216Z" fill="#8B8787"/>
            <path d="M8.92732 14.3736C8.85714 14.5174 8.78136 14.6742 8.69876 14.8428L8.7118 14.8514C7.97331 16.3762 6.28951 18.8566 2.98773 19.3642H3.01196L2.98773 19.3692C2.97542 19.372 2.9638 19.3773 2.95357 19.3847C2.8132 19.4287 2.42067 19.5136 1.76044 19.3816C1.93186 17.5005 2.74178 15.8518 4.41626 14.7541C5.99076 13.7221 7.69878 13.1321 9.14346 13.7996C9.16086 13.8071 9.17763 13.8157 9.19502 13.8238C9.11986 13.98 9.0298 14.1628 8.92732 14.3736Z" fill="#8B8787"/>
            <path d="M17.6351 21.7591C17.6351 21.9184 17.4488 22.1267 17.2985 22.2333C16.7035 22.6465 16.0952 23.0415 15.4737 23.4184C15.234 23.564 14.9532 23.6409 14.6383 23.771C14.111 23.044 13.3396 21.9668 12.5508 20.9026C12.5322 20.8778 12.5142 20.8542 12.4968 20.8331L14.57 17.7025L15.3321 17.9263L16.0389 18.1339L17.2811 18.4977L17.5128 18.5659C17.5569 19.6537 17.6196 20.7061 17.6351 21.7591Z" fill="#8B8787"/>
            <path d="M25.648 16.2856C25.1592 16.7567 24.6617 17.2191 24.1853 17.7025C23.9729 17.9182 23.8027 18.1754 23.6213 18.4035L20.2177 16.8292V13.8107C21.0699 13.6812 21.9276 13.5895 22.7642 13.4029C23.094 13.3292 23.376 13.0428 23.6797 12.8538C23.7605 13.1798 23.7654 13.5535 23.9362 13.8213C24.4735 14.6642 25.0729 15.4669 25.648 16.2856Z" fill="#8B8787"/>
            <path d="M24.476 26.2006C23.7027 26.2582 22.9294 26.3246 22.1555 26.371C21.8555 26.3896 21.5531 26.371 21.2152 26.371C21.0121 25.4265 20.8208 24.5352 20.6245 23.6241L23.7959 21.3562L26.1709 22.8369C25.9293 23.4214 25.7213 24.0226 25.4368 24.586C25.1523 25.1494 24.7995 25.6651 24.476 26.2006Z" fill="#8B8787"/>
            <path d="M11.9328 24.3753C10.7142 25.5529 8.79193 26.1312 7.30005 25.8281C8.5112 22.6869 10.7521 21.1721 10.7521 21.1721L11.9328 24.3753Z" fill="#8B8787"/>
            <path d="M23.1226 10.2958L23.4331 9.94378C23.9393 10.2369 24.271 10.5016 24.6474 10.6082C24.7672 10.6429 25.1902 10.219 25.1548 10.0987C25.0436 9.72685 24.7821 9.39401 24.5058 8.92916C24.5958 8.82317 24.8045 8.57897 25.1057 8.22754C26.1523 9.28802 27.1697 10.3057 28.1647 11.3452C28.264 11.4493 28.3001 11.717 28.2392 11.854C27.9684 12.4577 27.6516 13.0409 27.3765 13.5833L23.1226 10.2958Z" fill="#8B8787"/>
            <path d="M23.4518 3.10419C25.2859 2.58913 27.2287 2.22903 29.5038 2.41187L25.8995 5.66337L23.4518 3.10419Z" fill="#8B8787"/>
            <path d="M29.2734 9.11818C28.3839 8.22194 27.5821 7.41434 26.7213 6.54599L29.9628 2.95422C30.2075 5.1787 29.8131 7.11001 29.2734 9.11818Z" fill="#8B8787"/>
            <path d="M14.562 15.1464L13.5216 14.6165C13.6055 14.4268 13.6657 14.0977 13.8576 13.887C14.9115 12.7271 16.2732 11.8889 17.7848 11.4697C17.7296 11.924 17.6768 12.3536 17.6221 12.8048C16.5855 13.5591 15.2613 14.0927 14.562 15.1464Z" fill="#8B8787"/>
            <path d="M14.7868 26.2918L15.5601 25.7997L15.2682 25.5344C15.5017 25.7043 15.7166 25.9063 15.9712 26.0377C16.7166 26.4139 17.4774 26.7592 18.2314 27.118C18.3595 27.1722 18.4831 27.2365 18.601 27.3102C19.6904 28.0645 19.6898 28.0663 20.763 28.1159C18.4886 28.4103 16.0222 27.6275 14.7868 26.2918Z" fill="#8B8787"/>
            <path d="M28.1752 22.4081C28.1367 21.9935 28.5659 21.3768 27.7026 21.3185C28.0299 19.9425 28.1902 18.5814 27.3597 17.304C27.5229 16.9693 27.6866 16.6346 27.851 16.2999C28.8554 18.2883 28.8864 20.3287 28.1752 22.4081Z" fill="#8B8787"/>
            <path d="M9.19438 13.8269C9.11985 13.98 9.02979 14.1628 8.92731 14.3736C9.03041 14.1288 9.10059 13.931 9.14283 13.8027C9.16022 13.8102 9.17699 13.8188 9.19438 13.8269Z" fill="#8B8787"/>
        </svg>
    )
}

export const LogoutIcon = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
        xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9863 7.98282L16.5423 9.42673L19.1844 12.079H8.76976V14.1271H19.1844L16.5423 16.7692L17.9863 18.2234L23.1065 13.1031L17.9863 7.98282ZM4.67354 5.93471H12.866V3.8866H4.67354C3.54708 3.8866 2.62543 4.80825 2.62543 5.93471V20.2715C2.62543 21.3979 3.54708 22.3196 4.67354 22.3196H12.866V20.2715H4.67354V5.93471Z" fill="#F06E0C"/>
        </svg>
    )
}

export const WarningIcon = () => {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.374209 17.8427C-0.0117553 18.5093 0.469303 19.3437 1.23964 19.3437H20.3C21.0703 19.3437 21.5513 18.5093 21.1654 17.8427L11.6352 1.38149C11.2501 0.7162 10.2895 0.7162 9.90437 1.38148L0.374209 17.8427ZM11.7938 15.2715C11.7938 15.8238 11.3461 16.2715 10.7938 16.2715H10.7457C10.1935 16.2715 9.74574 15.8238 9.74574 15.2715V15.2234C9.74574 14.6711 10.1935 14.2234 10.7457 14.2234H10.7938C11.3461 14.2234 11.7938 14.6711 11.7938 15.2234V15.2715ZM11.7938 11.1753C11.7938 11.7276 11.3461 12.1753 10.7938 12.1753H10.7457C10.1935 12.1753 9.74574 11.7276 9.74574 11.1753V9.0791C9.74574 8.52681 10.1935 8.0791 10.7457 8.0791H10.7938C11.3461 8.0791 11.7938 8.52681 11.7938 9.0791V11.1753Z" fill="#8B8787"/>
        </svg>
    )
}

export const UserIcon = () => {
    return (
        <svg width="19" height="17" viewBox="0 0 19 17" fill="none"
        xmlns="http://www.w3.org/2000/svg">
            <path d="M9.30177 8.86461C11.7471 8.86461 13.7278 7.08205 13.7278 4.88122C13.7278 2.6804 11.7471 0.897827 9.30177 0.897827C6.85638 0.897827 4.87574 2.6804 4.87574 4.88122C4.87574 7.08205 6.85638 8.86461 9.30177 8.86461ZM9.30177 10.8563C6.34739 10.8563 0.449707 12.1907 0.449707 14.8397V16.8314H18.1538V14.8397C18.1538 12.1907 12.2561 10.8563 9.30177 10.8563Z" fill="#8B8787"/>
        </svg>
    )
}

export const BallotIcon = () => {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none"
        xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7217 10.4158H18.8419V8.36765H13.7217V10.4158ZM13.7217 17.5841H18.8419V15.536H13.7217V17.5841ZM19.866 22.1924H5.52922C4.40276 22.1924 3.48111 21.2707 3.48111 20.1443V5.80751C3.48111 4.68105 4.40276 3.7594 5.52922 3.7594H19.866C20.9925 3.7594 21.9141 4.68105 21.9141 5.80751V20.1443C21.9141 21.2707 20.9925 22.1924 19.866 22.1924ZM6.55327 11.9518H11.6735V6.83156H6.55327V11.9518ZM7.57733 7.85562H10.6495V10.9278H7.57733V7.85562ZM6.55327 19.1202H11.6735V13.9999H6.55327V19.1202ZM7.57733 15.024H10.6495V18.0962H7.57733V15.024Z" fill="#8B8787"/>
        </svg>
    )
}

export const StarCheckIcon = ({color}) => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4025 19.8481L12.5676 17.7884C11.9592 17.4599 11.3426 17.147 10.7507 16.7914C10.416 16.5891 10.1454 16.5881 9.79719 16.7737C8.13885 17.6581 6.45875 18.5153 4.78591 19.3809C4.58898 19.4852 4.38894 19.578 4.10599 19.7188C4.16196 19.3193 4.19616 19.0013 4.25317 18.6874C4.57032 16.9291 4.88644 15.1687 5.22018 13.4125C5.29481 13.0204 5.2264 12.743 4.92272 12.4479C3.56081 11.1193 2.22274 9.76355 0.875342 8.41721C0.743712 8.28477 0.62141 8.14398 0.408936 7.91351C1.15726 7.80922 1.80816 7.70494 2.46009 7.61525C3.91113 7.40668 5.36218 7.1981 6.82048 7.01665C6.9634 7.01038 7.10152 6.96292 7.21839 6.8799C7.33526 6.79688 7.42595 6.68181 7.47966 6.5484C8.3192 4.83601 9.18153 3.1351 10.0376 1.43106C10.1413 1.22248 10.2532 1.01391 10.4128 0.70105C10.9705 1.86072 11.4814 2.91818 11.9893 3.97669C12.3935 4.82141 12.8185 5.65883 13.1895 6.51607C13.3585 6.89985 13.6041 7.04376 14.0083 7.10216C16.0056 7.39208 17.9997 7.71224 19.9949 8.02301C20.0956 8.04763 20.1949 8.07756 20.2924 8.1127C19.8778 8.50899 19.5337 8.86565 19.1523 9.17225C19.0704 9.23899 18.8341 9.15452 18.69 9.09091C15.8283 7.8134 12.7324 9.46112 12.1779 12.5616C11.7634 14.8559 13.3056 17.2023 15.6304 17.7655C16.0035 17.8552 16.1486 17.9991 16.1735 18.3724C16.2056 18.8208 16.3062 19.2599 16.4025 19.8481Z" fill={color}/>
            <path d="M12.9286 13.1844C12.936 12.4571 13.1694 11.7482 13.5994 11.1471C14.0293 10.546 14.6366 10.0797 15.3445 9.80688C16.0524 9.53409 16.8293 9.46709 17.5771 9.61432C18.325 9.76155 19.0103 10.1164 19.5466 10.6342C20.0829 11.1519 20.4463 11.8093 20.5908 12.5235C20.7353 13.2377 20.6545 13.9766 20.3585 14.6471C20.0626 15.3176 19.5648 15.8896 18.928 16.2909C18.2911 16.6923 17.5437 16.905 16.7801 16.9023C16.2697 16.9029 15.7643 16.8067 15.2933 16.6195C14.8223 16.4322 14.3952 16.1576 14.0367 15.8116C13.6783 15.4655 13.3957 15.0551 13.2055 14.604C13.0152 14.153 12.9211 13.6704 12.9286 13.1844ZM16.5136 14.1049L15.3851 13.1684C15.0769 12.9127 14.7582 12.8699 14.4708 13.1804C14.2013 13.4789 14.3078 13.7695 14.5868 14.0103C15.0257 14.3885 15.4645 14.7667 15.918 15.1279C16.4635 15.5628 16.9525 15.4882 17.3725 14.9289C17.5439 14.699 17.7079 14.4641 17.873 14.2323C18.291 13.6421 18.7089 13.049 19.1269 12.4609C19.3703 12.1215 19.5448 11.7792 19.1269 11.4538C18.8134 11.2099 18.4916 11.3314 18.152 11.7951C17.9733 12.0399 17.803 12.2927 17.6296 12.5375L16.5136 14.1049Z" fill={color}/>
        </svg>

    )
}

export const BulbIcon = ({color}) => {
    return (
        <svg width="24" height="30" viewBox="0 0 24 30" fill="none"
        xmlns="http://www.w3.org/2000/svg">
            <path d="M9.93767 22.2835C9.6084 22.2835 9.28031 22.2835 8.95103 22.2835C8.8313 22.2835 8.72952 22.274 8.72353 22.1171C8.68282 20.8288 8.13203 19.7176 7.53334 18.6137C6.93465 17.5098 6.30243 16.4274 5.73727 15.3067C5.09906 14.0327 4.73386 12.6726 4.81528 11.2358C4.97214 8.44238 6.3144 6.42248 8.85524 5.2491C10.6179 4.41943 12.6309 4.29394 14.4829 4.89828C16.6658 5.57597 18.2666 6.93374 19.0521 9.12845C19.5933 10.6395 19.585 12.1828 19.1383 13.7262C18.7157 15.1822 17.9517 16.4717 17.2082 17.772C16.7065 18.6484 16.1796 19.5177 15.8767 20.4863C15.7326 20.997 15.6246 21.5171 15.5534 22.0429C15.5246 22.2225 15.4791 22.2919 15.2948 22.2895C14.6458 22.2823 13.9968 22.2811 13.3478 22.2895C13.1586 22.2895 13.0976 22.2237 13.1083 22.0381C13.1482 21.1447 13.3338 20.2638 13.6579 19.4303C14.186 18.0186 15.0014 16.7555 15.7258 15.4456C16.17 14.641 16.5831 13.8244 16.8262 12.9312C17.4548 10.6143 16.5376 8.14185 13.9609 7.29534C12.3276 6.75773 10.7507 6.89183 9.27073 7.78624C7.65068 8.76446 7.0472 10.5952 7.32499 12.3361C7.53094 13.6268 8.16316 14.7391 8.78101 15.8634C9.36413 16.9351 9.95204 18.0055 10.4789 19.1046C10.899 20.0091 11.1331 20.9887 11.1674 21.9854C11.1782 22.2105 11.1207 22.3075 10.8764 22.2919C10.5627 22.268 10.249 22.2835 9.93767 22.2835Z" fill={color}/>
            <path d="M12.1376 24.5142H9.90818C9.51242 24.5142 9.2516 24.2525 9.24707 23.86C9.24253 23.4674 9.50562 23.1848 9.91499 23.1835C11.4081 23.1835 12.9012 23.1835 14.3942 23.1835C14.5351 23.1734 14.6744 23.2212 14.7876 23.3185C14.9007 23.4157 14.9803 23.5561 15.0123 23.7147C15.0379 23.8653 15.0175 24.0215 14.9544 24.1565C14.8913 24.2916 14.7895 24.3972 14.6664 24.4553C14.5698 24.4976 14.4669 24.5171 14.3636 24.5129C13.622 24.5168 12.8804 24.5142 12.1376 24.5142Z" fill={color}/>
            <path d="M12.1441 26.7363C11.4015 26.7363 10.6585 26.7363 9.91518 26.7363C9.50342 26.7363 9.24253 26.5616 9.24707 26.2847C9.2516 26.0079 9.5125 25.848 9.90838 25.848H14.3651C14.7576 25.848 15.0208 26.0279 15.023 26.29C15.0253 26.552 14.7644 26.7354 14.3719 26.7363C13.6289 26.7371 12.8871 26.7363 12.1441 26.7363Z" fill={color}/>
            <path d="M1.86091 17.8505C1.81883 17.82 1.78244 17.7834 1.75313 17.7424C1.59146 17.424 1.43841 17.1007 1.27781 16.7813C1.21422 16.6565 1.25949 16.5985 1.39206 16.5454C2.10414 16.2585 2.8137 15.9666 3.52075 15.6699C3.69859 15.5962 3.79991 15.6119 3.88074 15.8006C4.00685 16.0954 4.16636 16.3784 4.32265 16.6614C4.38301 16.7715 4.37762 16.8304 4.24397 16.8845C3.48411 17.192 2.72748 17.5065 1.96977 17.817C1.93421 17.8301 1.89786 17.8412 1.86091 17.8505Z" fill={color}/>
            <path d="M23.0206 16.6202C22.961 16.7597 22.9142 16.8717 22.8663 16.9836C22.7599 17.2351 22.6535 17.4855 22.5471 17.739C22.5014 17.853 22.4301 17.8723 22.3194 17.8296C21.5597 17.5323 20.8 17.2341 20.0381 16.9439C19.8881 16.8869 19.8891 16.8045 19.9455 16.6925C20.0988 16.3871 20.2541 16.0746 20.4052 15.7641C20.4637 15.643 20.5244 15.5941 20.6787 15.6552C21.4011 15.9453 22.13 16.2192 22.8546 16.5032C22.9146 16.5358 22.9703 16.5751 23.0206 16.6202Z" fill={color}/>
            <path d="M22.3755 10.7415C22.8009 10.7415 23.2214 10.7415 23.6504 10.7415C23.8304 10.7415 23.915 10.7764 23.9089 10.9301C23.8981 11.249 23.9017 11.5669 23.9089 11.8857C23.9089 12.0197 23.8497 12.0744 23.6673 12.0744C22.7924 12.0694 21.9172 12.0694 21.0415 12.0744C20.8506 12.0744 20.7914 12.0178 20.7999 11.8725C20.8119 11.5612 20.8156 11.249 20.7999 10.9377C20.7926 10.7848 20.8651 10.7415 21.0488 10.749C21.4922 10.749 21.9345 10.7415 22.3755 10.7415Z" fill={color}/>
            <path d="M1.8881 12.0744C1.46335 12.0744 1.04101 12.0744 0.612628 12.0744C0.422638 12.0744 0.357291 12.0225 0.360921 11.8771C0.369795 11.5587 0.369795 11.2405 0.360921 10.9227C0.360921 10.7821 0.428688 10.7405 0.602947 10.7415C1.47908 10.7471 2.354 10.7415 3.22892 10.7415C3.39955 10.7415 3.47095 10.7792 3.47095 10.9237C3.45966 11.2421 3.45966 11.5599 3.47095 11.8771C3.47095 12.03 3.39592 12.0744 3.2144 12.0725C2.7715 12.0744 2.32859 12.0744 1.8881 12.0744Z" fill={color}/>
            <path d="M23.0206 6.27457C22.9815 6.32896 22.938 6.3796 22.8907 6.42591C22.2079 6.95683 21.5248 7.48568 20.8412 8.01247C20.7262 8.10426 20.632 8.10798 20.5504 7.943C20.3664 7.57086 20.1733 7.21112 19.9687 6.85759C19.8733 6.69385 19.894 6.6095 20.0388 6.49538C20.6672 6.01904 21.291 5.53525 21.9102 5.04403C22.0723 4.91998 22.1712 4.94107 22.2804 5.1197C22.4804 5.44718 22.6999 5.76102 22.9103 6.08106C22.9471 6.13688 22.977 6.19766 23.0206 6.27457Z" fill={color}/>
            <path d="M4.8038 6.6623C4.77502 6.72496 4.752 6.78135 4.72437 6.83398C4.53097 7.19991 4.32836 7.56083 4.14877 7.93303C4.07049 8.08967 3.98645 8.11599 3.85982 8.01824C3.1691 7.47937 2.47838 6.9405 1.78765 6.40163C1.76414 6.38962 1.74359 6.37168 1.72771 6.34929C1.71184 6.32691 1.70109 6.30072 1.69636 6.27292C1.69164 6.24511 1.69307 6.21647 1.70053 6.18939C1.708 6.1623 1.72129 6.13754 1.7393 6.11716C1.98336 5.75374 2.21705 5.38279 2.46917 5.02689C2.50068 4.99835 2.5383 4.97891 2.57854 4.97037C2.61878 4.96182 2.66035 4.96445 2.69941 4.97802C3.37862 5.49057 4.04977 6.01064 4.71977 6.53572C4.75346 6.57308 4.78179 6.61575 4.8038 6.6623Z" fill={color}/>
            <path d="M8.35837 3.72247C8.30122 3.77752 8.23903 3.82691 8.17263 3.86996C7.81337 4.07323 7.43944 4.26038 7.08996 4.47232C6.93599 4.56404 6.84557 4.5219 6.77836 4.38556C6.41177 3.70264 6.05129 3.0222 5.70302 2.33184C5.68995 2.28476 5.68905 2.23506 5.70039 2.18753C5.71173 2.13999 5.73494 2.09621 5.76779 2.06041C6.15149 1.83111 6.54618 1.61793 6.94699 1.41715C6.98765 1.4078 7.02999 1.40915 7.06999 1.42108C7.10998 1.43302 7.1463 1.45513 7.1755 1.48531C7.56531 2.19302 7.94045 2.90693 8.32048 3.6196C8.33148 3.64191 8.33759 3.66793 8.35837 3.72247Z" fill={color}/>
            <path d="M9.24701 28.0696H15.023C14.9914 28.1243 14.9822 28.163 14.9584 28.179C14.4415 28.5286 13.9325 28.8902 13.3997 29.213C13.2048 29.3179 12.9887 29.3759 12.768 29.3825C12.3513 29.4092 11.9333 29.4092 11.5165 29.3825C11.2958 29.3755 11.0796 29.317 10.8849 29.2117C10.3574 28.8928 9.86022 28.5352 9.35119 28.191C9.31252 28.1542 9.27762 28.1135 9.24701 28.0696Z" fill={color}/>
            <path d="M17.4118 1.41101C17.4418 1.41979 17.4713 1.43123 17.4999 1.44522C17.8419 1.66268 18.186 1.87769 18.5239 2.10981C18.5472 2.14032 18.5637 2.1774 18.5718 2.21756C18.5798 2.25773 18.5792 2.29967 18.57 2.33948C18.2689 3.03095 17.9556 3.71753 17.6484 4.40289C17.592 4.52506 17.5193 4.55804 17.4005 4.47619C17.1036 4.26973 16.7984 4.07792 16.4943 3.88734C16.3683 3.80793 16.3099 3.73952 16.398 3.55138C16.7052 2.91 16.9838 2.25397 17.2787 1.60648C17.3171 1.53587 17.3617 1.47034 17.4118 1.41101Z" fill={color}/>
            <path d="M12.7967 1.85736C12.7967 2.21559 12.7967 2.57249 12.7967 2.93072C12.7967 3.12125 12.7441 3.19907 12.6057 3.18699C12.2983 3.16821 11.9899 3.16552 11.6825 3.17626C11.5374 3.17626 11.4677 3.11856 11.4686 2.90791C11.4686 2.19234 11.4686 1.47676 11.4686 0.76118C11.4686 0.597492 11.5078 0.521015 11.6319 0.522356C11.9673 0.528618 12.3024 0.528618 12.6372 0.522356C12.7594 0.522356 12.8043 0.586758 12.8014 0.754472C12.789 1.1221 12.7967 1.48973 12.7967 1.85736Z" fill={color}/>
        </svg>

    )
}

export const EditIcon = () => {
    return (
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path d="M9.48003 4.67035L7.55993 2.75024L2.92096 7.38921V9.30932H4.84106L9.48003 4.67035ZM3.94501 8.28526V7.8142L7.55993 4.19928L8.03099 4.67035L4.41608 8.28526H3.94501Z" fill="#2F970C"/>
                <path d="M10.965 3.18541C11.1647 2.98572 11.1647 2.66314 10.965 2.46345L9.76682 1.26531C9.66441 1.1629 9.53641 1.11682 9.40328 1.11682C9.27527 1.11682 9.14214 1.16802 9.04486 1.26531L8.10785 2.20232L10.028 4.12242L10.965 3.18541Z" fill="#2F970C"/>
                <path d="M12.1375 10.3334H1.89691V12.3815H12.1375V10.3334Z" fill="#2F970C"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="12.2887" height="12.2887" fill="white" transform="translate(0.872864 0.0927734)"/>
                </clipPath>
            </defs>
        </svg>
        
    )
}

export const CancelIcon = () => {
    return (
        <span>
            <span className="iconify nav-cancel-btn" data-icon="clarity:times-line"></span>
        </span>
    )
}

export const ConvertIcon = () => {
    return (
        <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5181 10.7714H18.8183L22.9185 4.42624C24.2975 6.56035 25.6355 8.63465 27 10.7475C26.8673 10.7475 26.7863 10.7608 26.708 10.7621C25.9567 10.7714 25.2054 10.7847 24.4515 10.7874C24.2869 10.7874 24.2219 10.8246 24.2192 11.0093C24.1489 16.1771 20.3461 20.6792 15.277 21.5177C11.6627 22.1143 8.48643 21.147 5.79859 18.6341C5.64064 18.4866 5.48667 18.3365 5.31146 18.169L6.8578 15.7585C8.45988 17.6654 10.4416 18.7976 12.9356 18.9265C14.5855 19.0115 16.1332 18.6607 17.5401 17.781C20.1045 16.1771 21.3987 13.8437 21.5181 10.7714Z" fill="white"/>
            <path d="M21.2459 3.55369L19.7667 5.89648C19.5582 5.69051 19.364 5.51111 19.1841 5.31842C17.9447 3.98956 16.4577 3.11117 14.6735 2.85072C11.9184 2.44541 9.53081 3.23476 7.57851 5.29451C6.32541 6.60155 5.55496 8.31078 5.39814 10.1316C5.37859 10.3309 5.39814 10.5302 5.37729 10.7336C5.36686 10.8877 5.43724 10.9196 5.57148 10.9196C6.28046 10.9196 6.98943 10.9276 7.69841 10.9329C7.77531 10.9329 7.8535 10.9329 7.99816 10.9329L3.98019 17.2623L0 10.986L0.0338848 10.9116H2.66128C2.76163 7.64926 3.91242 4.90515 6.29219 2.74707C7.76119 1.39511 9.57847 0.499285 11.53 0.165074C13.2984 -0.15335 15.1171 -0.00955113 16.8161 0.583038C18.5152 1.17563 20.0393 2.19767 21.2459 3.55369Z" fill="white"/>
        </svg>
    )
}


export const BarCodeIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4824 16.403C12.4824 15.594 12.4727 14.785 12.4824 13.9773C12.4991 12.8331 13.2075 12.128 14.3534 12.1211C15.9757 12.1109 17.5961 12.1109 19.2147 12.1211C20.4231 12.1211 21.1329 12.8289 21.1398 14.0272C21.1495 15.6452 21.1523 17.2619 21.1398 18.8757C21.1315 20.0227 20.4328 20.7444 19.2883 20.7583C17.6355 20.7791 15.9798 20.7763 14.3256 20.7583C13.2047 20.7472 12.5199 20.0407 12.4783 18.9242C12.4783 18.8909 12.4783 18.8577 12.4783 18.8258L12.4824 16.403ZM19.4022 13.897H14.2631V19.0309C15.9076 19.0309 17.5077 19.0309 19.1092 19.017C19.1763 18.9959 19.2371 18.9581 19.2856 18.9072C19.3342 18.8564 19.3691 18.794 19.3869 18.7261C19.4133 17.1344 19.4022 15.5344 19.4022 13.897Z" fill="#FFF8F4"/>
            <path d="M12.4825 7.78931C12.4825 8.63156 12.4922 9.47381 12.4825 10.3161C12.4686 11.4243 11.7741 12.1169 10.6768 12.1169C9.03973 12.1252 7.40122 12.1252 5.76132 12.1169C4.53487 12.1169 3.85012 11.4354 3.8404 10.2163C3.82928 8.61494 3.82928 7.01309 3.8404 5.41078C3.84595 4.20558 4.54043 3.50463 5.75994 3.49493C7.38224 3.48108 9.00269 3.48108 10.6213 3.49493C11.7755 3.50463 12.4672 4.21112 12.4769 5.36229C12.488 6.1713 12.4825 6.9803 12.4825 7.78931ZM10.7338 5.28472C10.6743 5.26093 10.6123 5.24375 10.5491 5.23346C9.01288 5.23346 7.47669 5.23346 5.9405 5.22099C5.60576 5.22099 5.57104 5.39277 5.57104 5.65597C5.57104 7.10636 5.57104 8.55675 5.57104 10.0085C5.57884 10.1296 5.59556 10.25 5.62104 10.3687H10.7268L10.7338 5.28472Z" fill="#FFF8F4"/>
            <path d="M17.7535 5.28749C17.723 4.71675 17.6924 4.14463 17.6549 3.45615C18.4688 3.50186 19.2189 3.45615 19.9286 3.60853C20.2613 3.6729 20.5611 3.85082 20.7766 4.1117C20.992 4.37259 21.1096 4.70016 21.1092 5.03814C21.1634 6.62013 21.1412 8.20351 21.1481 9.78688C21.1481 9.96559 21.1481 10.1443 21.1481 10.3632H19.4383C19.43 10.1928 19.4119 10.0182 19.4119 9.8423C19.4119 8.45701 19.4022 7.07173 19.4119 5.68645C19.4119 5.35537 19.3286 5.1919 18.9675 5.22238C18.5508 5.25701 18.1466 5.25978 17.7369 5.27641L17.7535 5.28749Z" fill="#FFF8F4"/>
            <path d="M7.2462 18.6929V20.3801C6.53783 20.3801 5.80862 20.4605 5.1072 20.358C4.36689 20.2513 3.85714 19.542 3.84742 18.7275C3.82103 17.1289 3.84742 15.5316 3.83353 13.933C3.83353 13.656 3.92936 13.5645 4.20577 13.5479C5.57389 13.4648 5.57389 13.4579 5.57389 14.8141C5.57389 15.968 5.585 17.1206 5.57389 18.2773C5.57389 18.6194 5.67945 18.7497 6.02808 18.7247C6.44477 18.697 6.85312 18.7123 7.26564 18.7081L7.2462 18.6929Z" fill="#FFF8F4"/>
            <path d="M24.5943 20.3524C24.6887 21.5465 24.3234 22.7074 23.2053 23.4776C22.6826 23.8119 22.1002 24.0423 21.4899 24.1564C20.9344 24.2741 20.3385 24.1952 19.7621 24.216C19.5093 24.2243 19.3857 24.1301 19.4079 23.8724C19.4093 23.8563 19.4093 23.8401 19.4079 23.8239C19.3412 22.4747 19.3412 22.4747 20.6927 22.4747C22.2594 22.4747 22.8581 21.8831 22.8595 20.3302V20.2804C22.8595 18.9657 22.8595 18.9741 24.1956 19.0336C24.4943 19.0447 24.6123 19.1417 24.5956 19.4409C24.5804 19.6986 24.5943 19.9631 24.5943 20.3524Z" fill="#FFF8F4"/>
            <path d="M24.5942 4.00333C24.5942 5.25839 24.5942 5.25008 23.3275 5.23484C22.9497 5.23484 22.8316 5.10324 22.8566 4.74168C22.8816 4.34688 22.8719 3.9493 22.8566 3.55311C22.8205 2.48783 22.1232 1.79796 21.0607 1.77441C20.6315 1.76333 20.2009 1.75917 19.7703 1.77441C19.2884 1.78965 19.3537 1.41839 19.4259 1.19398C19.5426 0.815794 19.0092 0.200729 19.7717 0.0691267C20.1884 -0.00290792 20.6301 0.051118 21.0593 0.0566592C23.1191 0.0857501 24.5692 1.54584 24.5914 3.60714C24.5956 3.73874 24.5942 3.87173 24.5942 4.00333Z" fill="#FFF8F4"/>
            <path d="M0.351349 20.2555C0.393018 19.0087 0.393018 19.0281 1.61391 19.0239C2.0056 19.0239 2.15005 19.1375 2.1181 19.5323C2.09588 19.9282 2.09588 20.325 2.1181 20.7209C2.15422 21.7335 2.82647 22.4206 3.84041 22.4691C4.28627 22.4899 4.7349 22.4885 5.18075 22.4691C5.48771 22.4581 5.57105 22.5855 5.58216 22.8778C5.63078 24.209 5.63772 24.1689 4.26821 24.2174C2.02782 24.2949 0.826372 22.8972 0.518024 21.6227C0.40274 21.1822 0.401351 20.7126 0.351349 20.2555Z" fill="#FFF8F4"/>
            <path d="M0.391907 4.00905C0.391907 1.98238 1.26 0.602643 2.94064 0.199525C3.69623 0.0180533 4.51572 0.0914732 5.30187 0.070694C5.38798 0.070694 5.48243 0.209222 5.56021 0.296495C5.58521 0.324201 5.56021 0.39208 5.56994 0.435024C5.65189 1.76628 5.65189 1.76628 4.31988 1.76767C2.70035 1.76767 2.11005 2.3578 2.11005 3.98412C2.11005 4.26117 2.09616 4.54654 2.11005 4.82637C2.12949 5.12836 2.00449 5.25581 1.70586 5.22395C1.67302 5.22187 1.64008 5.22187 1.60724 5.22395C0.391907 5.24057 0.391907 5.24057 0.391907 4.00905Z" fill="#FFF8F4"/>
            <path d="M17.637 10.3812H14.2632V8.6842H16.2647C17.8648 8.6842 17.8647 8.6842 17.637 10.3812Z" fill="#FFF8F4"/>
            <path d="M10.7602 15.2643C9.64899 15.2643 8.63228 15.2726 7.61278 15.2504C7.50444 15.2504 7.39888 15.0523 7.29332 14.9457C7.28499 14.9374 7.29332 14.9138 7.29332 14.8972C7.22387 13.5521 7.22387 13.5521 8.56977 13.5521C9.17952 13.5521 9.78928 13.5618 10.3976 13.5521C10.6754 13.5452 10.7768 13.6518 10.7657 13.9275C10.7477 14.3472 10.7602 14.7711 10.7602 15.2643Z" fill="#FFF8F4"/>
            <path d="M7.26011 18.7053C7.27122 18.2606 7.30038 17.816 7.28649 17.3727C7.27816 17.0651 7.39622 16.9834 7.69068 16.9737C9.02547 16.9294 9.02408 16.9211 9.04214 18.2634C9.04214 18.4103 9.07131 18.5557 9.08658 18.7026L9.10186 18.6873H7.24622L7.26011 18.7053Z" fill="#FFF8F4"/>
            <path d="M15.891 5.26948L14.2506 5.19191V3.49494C14.7437 3.49494 15.2299 3.48247 15.716 3.50741C15.7966 3.50741 15.9327 3.67225 15.9327 3.76091C15.9327 4.26931 15.898 4.77632 15.8757 5.28472L15.891 5.26948Z" fill="#FFF8F4"/>
            <path d="M17.7356 5.27501C17.7064 5.80834 17.6786 6.34168 17.6481 6.90826H15.9813C15.9508 6.35968 15.9197 5.81296 15.8882 5.26808L15.8716 5.28332H17.7481L17.7356 5.27501Z" fill="#FFF8F4"/>
            <path d="M9.08649 18.7025H10.7296V20.4037H9.01843C9.04621 19.8163 9.0726 19.2525 9.10038 18.6887L9.08649 18.7025Z" fill="#FFF8F4"/>
            <path d="M17.6856 15.5801C17.6856 16.1051 17.7078 16.5498 17.6717 16.9862C17.6511 17.0592 17.6122 17.1259 17.5588 17.1799C17.5054 17.2339 17.4391 17.2736 17.3661 17.2951C16.9272 17.3325 16.4827 17.3103 15.9535 17.3103C15.9535 16.7798 15.9438 16.2783 15.9633 15.7782C15.9633 15.7089 16.1022 15.5926 16.1897 15.5898C16.6619 15.5718 17.1355 15.5801 17.6856 15.5801Z" fill="#FFF8F4"/>
            <path d="M7.29339 8.68418C7.29339 8.15778 7.27672 7.69925 7.30728 7.24349C7.32717 7.17809 7.36292 7.11859 7.41138 7.07026C7.45983 7.02194 7.51949 6.98627 7.58507 6.96644C8.04203 6.93596 8.50178 6.95397 9.02819 6.95397C9.02819 7.49146 9.03792 7.98046 9.01847 8.46946C9.00421 8.519 8.97763 8.56415 8.94121 8.60072C8.90479 8.63728 8.8597 8.66409 8.81013 8.67864C8.32538 8.68418 7.83369 8.68418 7.29339 8.68418Z" fill="#FFF8F4"/>
        </svg>

    )
}

