import Frame from "../Components/Frame";
import {Helmet} from "react-helmet";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";

const TermsAndConditions = () => {
    return (
        <Frame>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms {"&"} Conditions | ConvertedCode</title>
            </Helmet>
            <Container fluid className="wrapper">
                <Row className="pt-5 ml-n5 mr-n4">
                    <Col lg={12}>
                        <div className="tac-wrapper">
                            <span className="head">
                                Terms
                            </span>
                            <span className="tail">
                                 {"&"}
                            </span>
                            <span className="tail">
                                 Conditions
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-5 ml-5 mr-5 pl-5 pr-5">
                    <Col lg={12}>
                        <article className="tc-article">
                            <p>These terms and conditions apply to You, the user of this website (including any sub-domains, unless expressly excluded by their own terms and conditions), Convertedcode Limited, with the the following URL web address: <Link to="./">www.convertedcode.com</Link>.</p>
                            <br/>
                            <p><strong>PLEASE READ THE TERMS AND CONDITIONS CAREFULLY</strong> as they affect Your legal right.</p>
                            <br/>
                            <p><strong>1. Agreement</strong></p>
                            <p>a. By using this Website, You acknowledge that You have reviewed, considered the Terms of this Agreement and
                                understand the same and agree to be bound by it. If You do not agree with these Terms or do not intend to be
                                bound by it, You must quit the use of this Website immediately. In addition, when using these Services, You
                                shall be subject to any posted guidelines or rules applicable to such services. Accordingly, any
                                participation in this Service shall constitute acceptance of this Agreement. <br/>

                                b. By using this Website and agreeing to these Terms, You represent and warrant that You have the legal
                                capacity to accept these Terms.</p>
                            <br/>
                            <p><strong>2. Change to Service</strong></p>
                            <p>a. You accept that the Owner may vary, alter, amend, or update the Content or Service, at any time and
                                without Your consent in order to improve our service to you. <br/>

                                b. You also agree that the Services may not be available at all times and this may be as a result of the
                                maintenance from your destination bookies or for any other reason and we shall not be held liable for the
                                failure to provide this Service.

                            </p>
                            <br/>
                            <p><strong>3. Service Interruptions</strong></p>
                            <p>The Owner may from time to time interrupt Your access or use of this Website to perform some maintenance or
                                emergency services and You agree that the Owner shall not be held liable for any damage, loss which may
                                arise thereof.
                            </p>
                            <br/>
                            <p><strong>4. Acceptable Use</strong></p>
                            <p>a. We may provide You with other items in connection with Your use of this Service.<br/>

                                b. We hereby grant You the permission to use our Service for Your personal, non-commercial use to retrieve,
                                display and view the Content on a computer screen.<br/>

                                c. The license created under these Terms is limited, non-exclusive, non-transferable and revocable.<br/>

                                d. You agree that You will not use the Contents or Materials for any other purpose which may be contrary to
                                your license to use this Service.<br/>

                                e. Any unauthorized use by You shall terminate the permission granted by this Website.<br/>


                            </p>
                            <br/>
                            <p><strong> 5. Prohibited Use</strong></p>
                            <p>
                                a. You agree not to use the Service in the following manner:<br/>

                                (I). to perpetrate fraud;<br/>

                                (II). (V). to use this Service contrary to the applicable laws and regulations or in a way that causes, or
                                may cause harm to this Website, any person or business entity. We reserve the right to terminate Your use of
                                the Service for violating any of the prohibited uses.<br/>

                                (III). to create or transmit unnecessary spam to any person or URL;<br/>

                                (IV). to post, transmit or cause to be posted or transmitted, any communication or solicitation designed to
                                obtain password, account, or private information of other Users or persons;<br/>

                                (V). to post copyrighted content which does not belong to You and without obtaining the prior consent of the
                                author;<br/>

                                (VIII). publishing or distributing any obscene or defamatory material;<br/>

                                (IX). using this Service in any way that impacts user access to the Website ;<br/>

                                (X). to engage in advertisement or solicit any User to buy or sell products or services without obtaining
                                the prior consent of the Owner;<br/>

                                (XI). disseminating computer viruses or other software;<br/>

                                (XII). violating any intellectual property rights of the Owner or any third party;<br/>

                                (XIII). to use the Website or any of the Services for illegal spam activities.<br/><br/>

                                b. Additionally, you agree that You will not do as follows:<br/>

                                (I). interfere or attempt to interfere with the proper working of this Website ; or<br/>

                                (II). bypass any measures we may use to prevent or restrict access to this Website ;<br/>

                                (III). to interfere with or circumvent the security features of this Service;<br/>

                                (IV). to damage, disable, overburden or impair this Service or any other person's use of this Service.
                            </p>

                            <br/>
                            <p><strong>6. User Account</strong></p>
                            <p>a. You may be required to register with us to have access to our Service.<br/>

                                b. You will be required to provide certain personal information, which includes but not limited to Your
                                name, user name, email address and password. The information provided must be correct and accurate.<br/>

                                c. This personal information must not be disseminated to anyone and when You discover that Your information
                                has been compromised, You agree to notify us immediately. You also acknowledge that You are responsible for
                                the security of Your personal information and that the Owner does not accept liability for the security of
                                Your account as You agree to be responsible for maintaining the confidentiality of Your passwords or other
                                account identifiers which You choose and all activities under Your account.<br/>

                                d. The user may receive a free trial period for the following period: the duration of Euro2020 tournament
                                (of course this is only limited to Euro 2020 tournament, as there will not be free trial again starting from
                                the begininng of 2021-2022 season, after which the user will be required to subscribe to any plan of
                                choice.<br/>

                                e. The subscription for our Services will be automatically or manually renewed ( depends on the user's
                                choice) before or after the end of each subscription until the Services are terminated.<br/>

                                f. The Owner reserves the right to terminate Your account where You have provided false inaccurate or
                                incorrect information.<br/>

                                g. It is at the sole discretion of the Owner to terminate the account or refuse to sell any Product or
                                Services to any User at any time and for any reason.<br/>


                            </p>
                            <br/>
                            <p><strong>7. Privacy Policy</strong></p>
                            <p>To use our Service, we require that You provide certain personal information, By using our Service, You
                                hereby grant the Owner the authority to use Your personal information.<br/>

                                a. Information we collect and use:<br/>

                                For registered users: You provide personal information, such as Your name, user name, email address, if You
                                register for an account with the Service.<br/>

                                We may also require other information in relation to but not limited to the following: receiving
                                notifications by text message or email about marketing; receiving general emails from Us; commenting on our
                                Content on our Website; and the purchases You make.<br/>

                                For unregistered users: We will collect passive information from all registered and unregistered users.
                                These information include cookies, IP address information, and certain browser information.<br/>

                                Payment of Subscription: We may collect Your credit and/or debit card information, which includes Your card
                                number, password, etc, Your billing address, Your contact address and other information required for Your
                                subscription.<br/>

                                User experience: From time to time we may also request certain Personal Data that may be necessary to
                                improve our Service on the Website.<br/>

                                We may also receive information from external applications You use to access our Service or information
                                through various web technologies, such as cookies, log files, clear gifts with Your permission ONLY.<br/><br/>

                                b. How to protect Your information: We will use administrative security measures to reduce the risks of loss
                                or misuse. While the security of Your information is paramount to us, we cannot guarantee its absolute
                                security. If You choose to terminate Your account, You agree that the Owner will save Your information for
                                as long as your account is active with us.</p>
                            <br/>
                            <p><strong>8. Policy on Information Sharing.</strong></p>
                            <p>The sharing of Proprietary and confidential information is prohibited.</p>
                        </article>
                    </Col>
                </Row>
            </Container>
            <div className="mobile-wrapper">
                <div className="mobile-bet-term-header">
                    <h1 className="mobile-term-header-text">Terms {"&"} <br /> <span>Conditions</span></h1>
                </div>
                <div className="match-review-content pl-2 pr-4">
                <article className="tc-article">
                            <p>These terms and conditions apply to You, the user of this website (including any sub-domains, unless expressly excluded by their own terms and conditions), Convertedcode Limited, with the the following URL web address: <Link to="./">www.convertedcode.com</Link>.</p>
                            <br/>
                            <p><strong>PLEASE READ THE TERMS AND CONDITIONS CAREFULLY</strong> as they affect Your legal right.</p>
                            <br/>
                            <p><strong>1. Agreement</strong></p>
                            <p>a. By using this Website, You acknowledge that You have reviewed, considered the Terms of this Agreement and
                                understand the same and agree to be bound by it. If You do not agree with these Terms or do not intend to be
                                bound by it, You must quit the use of this Website immediately. In addition, when using these Services, You
                                shall be subject to any posted guidelines or rules applicable to such services. Accordingly, any
                                participation in this Service shall constitute acceptance of this Agreement. <br/>

                                b. By using this Website and agreeing to these Terms, You represent and warrant that You have the legal
                                capacity to accept these Terms.</p>
                            <br/>
                            <p><strong>2. Change to Service</strong></p>
                            <p>a. You accept that the Owner may vary, alter, amend, or update the Content or Service, at any time and
                                without Your consent in order to improve our service to you. <br/>

                                b. You also agree that the Services may not be available at all times and this may be as a result of the
                                maintenance from your destination bookies or for any other reason and we shall not be held liable for the
                                failure to provide this Service.

                            </p>
                            <br/>
                            <p><strong>3. Service Interruptions</strong></p>
                            <p>The Owner may from time to time interrupt Your access or use of this Website to perform some maintenance or
                                emergency services and You agree that the Owner shall not be held liable for any damage, loss which may
                                arise thereof.
                            </p>
                            <br/>
                            <p><strong>4. Acceptable Use</strong></p>
                            <p>a. We may provide You with other items in connection with Your use of this Service.<br/>

                                b. We hereby grant You the permission to use our Service for Your personal, non-commercial use to retrieve,
                                display and view the Content on a computer screen.<br/>

                                c. The license created under these Terms is limited, non-exclusive, non-transferable and revocable.<br/>

                                d. You agree that You will not use the Contents or Materials for any other purpose which may be contrary to
                                your license to use this Service.<br/>

                                e. Any unauthorized use by You shall terminate the permission granted by this Website.<br/>


                            </p>
                            <br/>
                            <p><strong> 5. Prohibited Use</strong></p>
                            <p>
                                a. You agree not to use the Service in the following manner:<br/>

                                (I). to perpetrate fraud;<br/>

                                (II). (V). to use this Service contrary to the applicable laws and regulations or in a way that causes, or
                                may cause harm to this Website, any person or business entity. We reserve the right to terminate Your use of
                                the Service for violating any of the prohibited uses.<br/>

                                (III). to create or transmit unnecessary spam to any person or URL;<br/>

                                (IV). to post, transmit or cause to be posted or transmitted, any communication or solicitation designed to
                                obtain password, account, or private information of other Users or persons;<br/>

                                (V). to post copyrighted content which does not belong to You and without obtaining the prior consent of the
                                author;<br/>

                                (VIII). publishing or distributing any obscene or defamatory material;<br/>

                                (IX). using this Service in any way that impacts user access to the Website ;<br/>

                                (X). to engage in advertisement or solicit any User to buy or sell products or services without obtaining
                                the prior consent of the Owner;<br/>

                                (XI). disseminating computer viruses or other software;<br/>

                                (XII). violating any intellectual property rights of the Owner or any third party;<br/>

                                (XIII). to use the Website or any of the Services for illegal spam activities.<br/><br/>

                                b. Additionally, you agree that You will not do as follows:<br/>

                                (I). interfere or attempt to interfere with the proper working of this Website ; or<br/>

                                (II). bypass any measures we may use to prevent or restrict access to this Website ;<br/>

                                (III). to interfere with or circumvent the security features of this Service;<br/>

                                (IV). to damage, disable, overburden or impair this Service or any other person's use of this Service.
                            </p>

                            <br/>
                            <p><strong>6. User Account</strong></p>
                            <p>a. You may be required to register with us to have access to our Service.<br/>

                                b. You will be required to provide certain personal information, which includes but not limited to Your
                                name, user name, email address and password. The information provided must be correct and accurate.<br/>

                                c. This personal information must not be disseminated to anyone and when You discover that Your information
                                has been compromised, You agree to notify us immediately. You also acknowledge that You are responsible for
                                the security of Your personal information and that the Owner does not accept liability for the security of
                                Your account as You agree to be responsible for maintaining the confidentiality of Your passwords or other
                                account identifiers which You choose and all activities under Your account.<br/>

                                d. The user may receive a free trial period for the following period: the duration of Euro2020 tournament
                                (of course this is only limited to Euro 2020 tournament, as there will not be free trial again starting from
                                the begininng of 2021-2022 season, after which the user will be required to subscribe to any plan of
                                choice.<br/>

                                e. The subscription for our Services will be automatically or manually renewed ( depends on the user's
                                choice) before or after the end of each subscription until the Services are terminated.<br/>

                                f. The Owner reserves the right to terminate Your account where You have provided false inaccurate or
                                incorrect information.<br/>

                                g. It is at the sole discretion of the Owner to terminate the account or refuse to sell any Product or
                                Services to any User at any time and for any reason.<br/>


                            </p>
                            <br/>
                            <p><strong>7. Privacy Policy</strong></p>
                            <p>To use our Service, we require that You provide certain personal information, By using our Service, You
                                hereby grant the Owner the authority to use Your personal information.<br/>

                                a. Information we collect and use:<br/>

                                For registered users: You provide personal information, such as Your name, user name, email address, if You
                                register for an account with the Service.<br/>

                                We may also require other information in relation to but not limited to the following: receiving
                                notifications by text message or email about marketing; receiving general emails from Us; commenting on our
                                Content on our Website; and the purchases You make.<br/>

                                For unregistered users: We will collect passive information from all registered and unregistered users.
                                These information include cookies, IP address information, and certain browser information.<br/>

                                Payment of Subscription: We may collect Your credit and/or debit card information, which includes Your card
                                number, password, etc, Your billing address, Your contact address and other information required for Your
                                subscription.<br/>

                                User experience: From time to time we may also request certain Personal Data that may be necessary to
                                improve our Service on the Website.<br/>

                                We may also receive information from external applications You use to access our Service or information
                                through various web technologies, such as cookies, log files, clear gifts with Your permission ONLY.<br/><br/>

                                b. How to protect Your information: We will use administrative security measures to reduce the risks of loss
                                or misuse. While the security of Your information is paramount to us, we cannot guarantee its absolute
                                security. If You choose to terminate Your account, You agree that the Owner will save Your information for
                                as long as your account is active with us.</p>
                            <br/>
                            <p><strong>8. Policy on Information Sharing.</strong></p>
                            <p>The sharing of Proprietary and confidential information is prohibited.</p>
                        </article>
                </div>
            </div>
        </Frame>
    )
}

export default TermsAndConditions
