import React from 'react';
import {getUserDetails} from '../../../services/dashboardService';
import { Spinner, Modal } from 'react-bootstrap';
import { useState } from '@hookstate/core';
import store from '../../../store/store';

const UserDetailsForm = () => {
    const [userId, setUserId] = React.useState("")
    const [userDetails, setUserDetails] = React.useState({
        currentUnit: "",
        email: "",
        totalConversions: "",
        conversionPlan: "",
        lastSubcriptionDate: ""
    })

    const [isSubmitting, setisSubmitting] = React.useState(false)
    const [modalShow, setModalShow] = React.useState(false)

    const {alertNotification} = useState(store)
    const {alertMessage} = useState(store)
    const {alertType} = useState(store)


    const handleSubmit = async () => {
        if(userId !== ""){
            setisSubmitting(true)
            let res = await getUserDetails(userId)
            setUserDetails(res.data)
            setisSubmitting(false)
            setModalShow(true)
        }
        else{
            alertType.set("danger")
                alertMessage.set("User Id is empty")
                alertNotification.set(true)
                setTimeout(() => {
                    alertNotification.set(false)
                }, 1000)  
        }
    }

  return (
    <>
        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="converter-modal"
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                User Details
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className="white paragraph"><b>User ID: </b>CC-{userDetails.id} </p>
            <p className="white paragraph"><b>Email: </b>{userDetails.email} </p>
            <p className="white paragraph"><b>Current Unit: </b>{userDetails.currentUnit} </p>
            <p className="white paragraph"><b>Current Plan: </b>{userDetails.conversionPlan} </p>
            <p className="white paragraph"><b>Total Conversions Done: </b>{userDetails.totalConversions} </p>
            <p className="white paragraph"><b>Last Subscription Date: </b>{new Date(userDetails.lastSubcriptionDate).toDateString()} </p>
        </Modal.Body>
    </Modal>
    <form className="pt-3 pl-5 pr-5">
    <div className="form-group">
        <input 
            type="text" 
            className="form-control" 
            placeholder="User ID" 
            name="id"
            onChange={(e) => setUserId(e.target.value)}
            value={userId}
        />
    </div>
    <div className="mr-btn">
        <button 
            type="submit" 
            className="btn-green form-btn"
            disabled={isSubmitting}
            onClick={handleSubmit}
        >
            {isSubmitting 
            ?
            <Spinner animation="border" size="sm"/>
            :
            "Post"
            }
        </button>
    </div>
</form>
    </>
    
  )
}

export default UserDetailsForm