import { Table, Modal } from "react-bootstrap";
import { usePaystackPayment, PaystackButton } from "react-paystack";
import React, { useEffect } from "react";
import {
  paymentInit,
  confirmPayment,
  paymentInitV2,
} from "../../services/paymentService";
import { useState } from "@hookstate/core";
import store from "../../store/store";
import Loader from "react-js-loader";
import { convertPaystackAmount } from "../../utils/Functions";

const SubscriptionPlanTable = () => {
  const [data, setData] = React.useState({});
  const [config, setConfig] = React.useState({});
  const [plan, setPlan] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);
  const [modalView, setModalView] = React.useState("summary");

  const [modalShow, setModalShow] = React.useState(false);

  const { alertNotification } = useState(store);
  const { alertMessage } = useState(store);
  const { alertType } = useState(store);

  const { user } = useState(store);

  const { conversionUnit } = useState(store);
  const { conversionPlan } = useState(store);

  const onSuccess = (reference) => {
    setModalView("processing");
    if (reference.status === "success") {
      const confirm = async () => {
        setIsLoading(true);
        try {
          const res = await confirmPayment(reference.reference);
          if (res.status === 200) {
            conversionPlan.set("Monthly");
            conversionUnit.set(res.data.unit);
            setModalView("completed");
          }
        } catch (err) {
          console.log(err);
        }

        setIsLoading(false);
      };
      confirm();
    }
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    alertType.set("danger");
    alertMessage.set("Payment Cancelled");
    alertNotification.set(true);
    setTimeout(() => {
      alertNotification.set(false);
    }, 3000);
  };

  const initiatePayment = async (plan) => {
    setIsLoading(true);
    setModalShow(true);
    try {
      const payload = {
        userId: user.get().id,
        plan: plan,
      };

      setPlan(plan);
      const res = await paymentInitV2(payload);
      if (res.status === 200) {
        const paymentConfig = {
          reference: res.data.reference,
          email: res.data.email,
          publicKey: res.data.publicKey,
          amount: res.data.amount,
          currency: res.data.currency,
          metadata: {
            userId: res.data.metadata ? res.data.metadata.userID : "",
            name: res.data.metadata ? res.data.metadata.username : "",
          },
          orderID: res.data.orderID,
        };
        setConfig(paymentConfig);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await paymentInit(user.get().id);
        setData(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetch();
  }, []);

  const Config5Unit = {
    reference: data.reference,
    email: data.email,
    publicKey: data.publicKey,
    amount: 10000,
    currency: data.currency,
    metadata: {
      userId: data.metadata ? data.metadata.userID : "",
      name: data.metadata ? data.metadata.username : "",
    },
    orderID: data.orderID,
  };

  const ConfigWeekendUnit = {
    reference: data.reference,
    email: data.email,
    publicKey: data.publicKey,
    amount: 25000,
    currency: data.currency,
    metadata: {
      userId: data.metadata ? data.metadata.userID : "",
      name: data.metadata ? data.metadata.username : "",
    },
    orderID: data.orderID,
  };

  const Config25Unit = {
    reference: data.reference,
    email: data.email,
    publicKey: data.publicKey,
    amount: 50000,
    currency: data.currency,
    metadata: {
      userId: data.metadata ? data.metadata.userID : "",
      name: data.metadata ? data.metadata.username : "",
    },
    orderID: data.orderID,
  };

  const Config50Unit = {
    reference: data.reference,
    email: data.email,
    publicKey: data.publicKey,
    amount: 100000,
    currency: data.currency,
    metadata: {
      userId: data.metadata ? data.metadata.userID : "",
      name: data.metadata ? data.metadata.username : "",
    },
    orderID: data.orderID,
  };

  const Config200Unit = {
    reference: data.reference,
    email: data.email,
    publicKey: data.publicKey,
    amount: 300000,
    currency: data.currency,
    metadata: {
      userId: data.metadata ? data.metadata.userID : "",
      name: data.metadata ? data.metadata.username : "",
    },
    orderID: data.orderID,
  };

  const dailyPlan = usePaystackPayment(Config5Unit);
  const weekendPlan = usePaystackPayment(ConfigWeekendUnit);
  const biWeeklyPlan = usePaystackPayment(Config25Unit);
  const monthlyPlan = usePaystackPayment(Config50Unit);
  const premiumPlan = usePaystackPayment(Config200Unit);

  return (
    <div className="punters-tips-table ml-n2">
      <h3 className="sub-title">Subscription Plans</h3>
      <Table striped hover variant="dark" size="sm">
        <thead>
          <tr>
            <th>
              {" "}
              <span className="punter">Plans</span>
            </th>
            <th>Price</th>
            <th>Duration</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          {/* <tr>
                        <td>5 Conversion Units</td>
                        <td> ₦100 </td>
                        <td> Daily </td>
                        <td> 
                            <button 
                                className="btn-green"
                                onClick={() => {
                                    dailyPlan(onSuccess, onClose)
                                }}
                            >
                                Subscribe
                            </button>
                        </td>
                    </tr> */}
          {/* <tr>
                        <td>20 Conversion Unit</td>
                        <td> ₦250 </td>
                        <td> Weekends </td>
                        <td> 
                            <button 
                                className="btn-green"
                                onClick={() => {
                                    weekendPlan(onSuccess, onClose)
                                }}
                            >
                                Subscribe
                            </button>
                        </td>
                    </tr> */}
          <tr>
            <td>15 Conversion Unit</td>
            <td> ₦500 </td>
            <td> 1 Month </td>
            <td>
              <button
                className="btn-green"
                onClick={() => {
                  // biWeeklyPlan(onSuccess, onClose)
                  initiatePayment("15Monthly");
                }}
              >
                Subscribe
              </button>
            </td>
          </tr>
          <tr>
            <td>
              30 <span className="bonus-text">+5</span> conversion Units
            </td>
            <td> ₦1,000 </td>
            <td> 1 Month </td>
            <td>
              <button
                className="btn-green"
                onClick={() => {
                  // monthlyPlan(onSuccess, onClose)
                  initiatePayment("35Monthly");
                }}
              >
                Subscribe
              </button>
            </td>
          </tr>
          {/* <tr>
                        <td>200 <span className="bonus-text">+20(Bonus)</span> conversion Units</td>
                        <td> ₦3,000 </td>
                        <td> 1 Month </td>
                        <td> 
                            <button 
                                className="btn-green"
                                onClick={() => {
                                    premiumPlan(onSuccess, onClose)
                                }}
                            >
                                Subscribe
                            </button>
                        </td>
                    </tr> */}
        </tbody>
      </Table>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="converter-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalView === "summary"
              ? "Payment Summary"
              : modalView === "completed"
              ? "Payment Completed"
              : "Payment Processing - Do Not Close"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="loading-game">
              <Loader type="bubble-scale" bgColor={"#2F970C"} size={50} />
            </div>
          ) : modalView === "summary" ? (
            <div>
              <div className="text">
                <span>Amount: </span> {config.currency}{" "}
                {convertPaystackAmount(config.amount)}
              </div>
              <div className="text">
                <span>Plan: </span> {plan}
              </div>
              <div className="text">
                <PaystackButton
                  className="btn-green btn-convert"
                  {...config}
                  text="Make Payment"
                  onClose={onClose}
                  onSuccess={onSuccess}
                />
              </div>
            </div>
          ) : modalView === "completed" ? (
            <div>
              <div className="text">
                <span>Payment for {plan} Completed </span>
              </div>
              <div className="icon-check">
                <span
                  className="iconify"
                  data-icon="bi:patch-check-fill"
                  data-inline="false"
                ></span>
              </div>
            </div>
          ) : (
            <div className="loading-game">
              <Loader type="bubble-scale" bgColor={"#2F970C"} size={50} />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SubscriptionPlanTable;
