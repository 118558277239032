import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Frame from "../Components/Frame";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import DashboardCard from "./subcomponents/DashboardCard";
import React, { useEffect } from "react";
import MatchReviewForm from "./subcomponents/MatchReviewForm";
import PuntersTipsForm from "./subcomponents/PuntersTipsForm";
import BetTermForm from "./subcomponents/BetTermForm";
import AvailableLeagueForm from "./subcomponents/AvailableLeagueForm";
import AvailableOptionForm from "./subcomponents/AvailableOptionForm";
import CreditUnitForm from "./subcomponents/CreditUnitForm";
import AssignRoleForm from "./subcomponents/AssignRoleForm";
import StatForm from "./subcomponents/StatForm";
import RegShopForm from "./subcomponents/RegShopForm";
import SubscriptionPlanTable from "../Components/SubscriptionPlanTable";
import { getFromLocalStorage, role } from "../../utils/Functions";
import {
  getUserPlan,
  getDailyConversionData,
  getWeekOfDayConversionData,
} from "../../services/dashboardService";
import { useState } from "@hookstate/core";
import store from "../../store/store";
import ContentLoader from "../Components/ContentLoader";
import { Bar, Line } from "react-chartjs-2";
import UserDetailsForm from "./subcomponents/UserDetailsForm";

const Dashboard = () => {
  let history = useHistory();
  const [key, setKey] = React.useState("match-review");
  const [isLoading, setIsLoading] = React.useState(true);

  //Bet9ja ChartState
  const [lineXaxisBet9ja, setLineXaxisBet9ja] = React.useState([]);
  const [lineDataBet9ja, setLineDataBet9ja] = React.useState([]);
  const [barXaxisBet9ja, setBarXaxisBet9ja] = React.useState([]);
  const [barDataBet9ja, setBarDataBet9ja] = React.useState([]);

  //Betking ChartState
  const [lineXaxisBetking, setLineXaxisBetking] = React.useState([]);
  const [lineDataBetking, setLineDataBetking] = React.useState([]);
  const [barXaxisBetking, setBarXaxisBetking] = React.useState([]);
  const [barDataBetking, setBarDataBetking] = React.useState([]);

  //Sportybet ChartState
  const [lineXaxisSportybet, setLineXaxisSportybet] = React.useState([]);
  const [lineDataSportybet, setLineDataSportybet] = React.useState([]);
  const [barXaxisSportybet, setBarXaxisSportybet] = React.useState([]);
  const [barDataSportybet, setBarDataSportybet] = React.useState([]);

  //22Bet ChartState
  const [lineXaxis22Bet, setLineXaxis22Bet] = React.useState([]);
  const [lineData22Bet, setLineData22Bet] = React.useState([]);
  const [barXaxis22Bet, setBarXaxis22Bet] = React.useState([]);
  const [barData22Bet, setBarData22Bet] = React.useState([]);

  //1xBet ChartState
  const [lineXaxis1xBet, setLineXaxis1xBet] = React.useState([]);
  const [lineData1xBet, setLineData1xBet] = React.useState([]);
  const [barXaxis1xBet, setBarXaxis1xBet] = React.useState([]);
  const [barData1xBet, setBarData1xBet] = React.useState([]);

  //Betwinner ChartState
  const [lineXaxisBetwinner, setLineXaxisBetwinner] = React.useState([]);
  const [lineDataBetwinner, setLineDataBetwinner] = React.useState([]);
  const [barXaxisBetwinner, setBarXaxisBetwinner] = React.useState([]);
  const [barDataBetwinner, setBarDataBetwinner] = React.useState([]);

  //MelBet ChartState
  const [lineXaxisMelbet, setLineXaxisMelbet] = React.useState([]);
  const [lineDataMelbet, setLineDataMelbet] = React.useState([]);
  const [barXaxisMelbet, setBarXaxisMelbet] = React.useState([]);
  const [barDataMelbet, setBarDataMelbet] = React.useState([]);

  useEffect(() => {
    const token = getFromLocalStorage("returnToken");
    if (!token) {
      history.push({
        pathname: "/",
      });
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getDailyConversionData(role("Bet9ja"));
      setLineXaxisBet9ja(res.data.days);
      setLineDataBet9ja(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getWeekOfDayConversionData(role("Bet9ja"));
      setBarXaxisBet9ja(res.data.day);
      setBarDataBet9ja(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getDailyConversionData(role("Betking"));
      setLineXaxisBetking(res.data.days);
      setLineDataBetking(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getWeekOfDayConversionData(role("Betking"));
      setBarXaxisBetking(res.data.day);
      setBarDataBetking(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getDailyConversionData(role("Sportybet"));
      setLineXaxisSportybet(res.data.days);
      setLineDataSportybet(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getWeekOfDayConversionData(role("Sportybet"));
      setBarXaxisSportybet(res.data.day);
      setBarDataSportybet(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getDailyConversionData(role("Bet22"));
      setLineXaxis22Bet(res.data.days);
      setLineData22Bet(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getWeekOfDayConversionData(role("Bet22"));
      setBarXaxis22Bet(res.data.day);
      setBarData22Bet(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getDailyConversionData(role("Bet1x"));
      setLineXaxis1xBet(res.data.days);
      setLineData1xBet(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getWeekOfDayConversionData(role("Bet1x"));
      setBarXaxis1xBet(res.data.day);
      setBarData1xBet(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getDailyConversionData(role("Betwinner"));
      setLineXaxisBetwinner(res.data.days);
      setLineDataBetwinner(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getWeekOfDayConversionData(role("Betwinner"));
      setBarXaxisBetwinner(res.data.day);
      setBarDataBetwinner(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getDailyConversionData(role("Melbet"));
      setLineXaxisMelbet(res.data.days);
      setLineDataMelbet(res.data.totalConversions);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getWeekOfDayConversionData(role("Melbet"));
      setBarXaxisMelbet(res.data.day);
      setBarDataMelbet(res.data.totalConversions);
    };
    fetch();
  }, []);
  const { user } = useState(store);
  const { conversionUnit } = useState(store);
  const { totalConversions } = useState(store);
  const { conversionPlan } = useState(store);

  useEffect(() => {
    try {
      const fetch = async () => {
        const res = await getUserPlan(user.get().id);
        totalConversions.set(res.data.totalConversions);
        conversionPlan.set(res.data.conversionPlan);
        conversionUnit.set(res.data.conversionUnit);
      };
      fetch();
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  }, []);

  const bet9jaBarChartData = {
    labels: barXaxisBet9ja,
    datasets: [
      {
        label: "Bet9ja Day of the Week Conversions (From 11/10/2021)",
        data: barDataBet9ja,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
        borderWidth: 1,
      },
    ],
  };

  const betkingBarChartData = {
    labels: barXaxisBetking,
    datasets: [
      {
        label: "Betking Day of the Week Conversions (From 11/10/2021)",
        data: barDataBetking,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
        borderWidth: 1,
      },
    ],
  };

  const sportybetBarChartData = {
    labels: barXaxisSportybet,
    datasets: [
      {
        label: "Sportybet Day of the Week Conversions (From 11/10/2021)",
        data: barDataSportybet,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
        borderWidth: 1,
      },
    ],
  };

  const bet22BarChartData = {
    labels: barXaxis22Bet,
    datasets: [
      {
        label: "22Bet Day of the Week Conversions (From 11/10/2021)",
        data: barData22Bet,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
        borderWidth: 1,
      },
    ],
  };

  const bet1xBarChartData = {
    labels: barXaxis1xBet,
    datasets: [
      {
        label: "1xBet Day of the Week Conversions (From 11/10/2021)",
        data: barData1xBet,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
        borderWidth: 1,
      },
    ],
  };

  const betwinnerBarChartData = {
    labels: barXaxisBetwinner,
    datasets: [
      {
        label: "Betwinner Day of the Week Conversions (From 09/12/2021)",
        data: barDataBetwinner,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
        borderWidth: 1,
      },
    ],
  };

  const melbetBarChartData = {
    labels: barXaxisMelbet,
    datasets: [
      {
        label: "Melbet Day of the Week Conversions (From 09/12/2021)",
        data: barDataMelbet,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
        borderWidth: 1,
      },
    ],
  };

  const barChartOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const bet9jaLineChartData = {
    labels: lineXaxisBet9ja,
    datasets: [
      {
        label: "Bet9ja Daily Conversions",
        data: lineDataBet9ja,
        fill: false,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
      },
    ],
  };

  const betkingLineChartData = {
    labels: lineXaxisBetking,
    datasets: [
      {
        label: "Betking Daily Conversions",
        data: lineDataBetking,
        fill: false,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
      },
    ],
  };

  const sportybetLineChartData = {
    labels: lineXaxisSportybet,
    datasets: [
      {
        label: "Sportybet Daily Conversions",
        data: lineDataSportybet,
        fill: false,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
      },
    ],
  };

  const bet22LineChartData = {
    labels: lineXaxis22Bet,
    datasets: [
      {
        label: "22Bet Daily Conversions",
        data: lineData22Bet,
        fill: false,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
      },
    ],
  };

  const bet1xLineChartData = {
    labels: lineXaxis1xBet,
    datasets: [
      {
        label: "1xBet Daily Conversions",
        data: lineData1xBet,
        fill: false,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
      },
    ],
  };

  const melbetLineChartData = {
    labels: lineXaxisMelbet,
    datasets: [
      {
        label: "Melbet Daily Conversions",
        data: lineDataMelbet,
        fill: false,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
      },
    ],
  };

  const betwinnerLineChartData = {
    labels: lineXaxisBetwinner,
    datasets: [
      {
        label: "Betwinner Daily Conversions",
        data: lineDataBetwinner,
        fill: false,
        backgroundColor: "rgba(47, 151, 12, 0.7)",
        borderColor: "rgb(47, 151, 12)",
      },
    ],
  };

  const lineChartOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <Frame>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard | ConvertedCode</title>
      </Helmet>
      {isLoading ? (
        <ContentLoader />
      ) : (
        <>
          <Container fluid className="wrapper">
            <Row className="pt-5 ml-n5 mr-n4 pl-5 pr-5">
              <Col lg={4}>
                <DashboardCard
                  title="Available Conversion Units"
                  value={conversionUnit.get()}
                  icon="ci:refresh-02"
                />
              </Col>
              <Col lg={4}>
                <DashboardCard
                  title="Total Conversions done"
                  value={totalConversions.get()}
                  icon="bi:patch-check-fill"
                />
              </Col>
              <Col lg={4}>
                <DashboardCard
                  title="Current Plan"
                  value={conversionPlan.get()}
                  icon="icon-park-outline:tag"
                />
              </Col>
            </Row>
            {user.get().isAdmin === "true" ||
            user.get().isAdmin === "editor" ? (
              <Row className="pt-5 ml-n5 mr-n4 pl-5 pr-5">
                <Col lg={12}>
                  <div className="admin-post">
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                      className="mb-3"
                    >
                      <Tab eventKey="match-review" title="Match Review">
                        <MatchReviewForm />
                      </Tab>
                      <Tab eventKey="punters-tips" title="Punters Tips">
                        <PuntersTipsForm />
                      </Tab>
                      <Tab eventKey="bet-term" title="Bet Terminology">
                        <BetTermForm />
                      </Tab>
                      <Tab eventKey="available-league" title="Available League">
                        <AvailableLeagueForm />
                      </Tab>
                      <Tab eventKey="available-option" title="Available Option">
                        <AvailableOptionForm />
                      </Tab>
                      <Tab eventKey="stat" title="Stat">
                        <StatForm />
                      </Tab>
                      {user.get().isAdmin === "true" && (
                        <Tab eventKey="credit-unit" title="Credit Unit(Manual)">
                          <CreditUnitForm />
                        </Tab>
                      )}
                      {user.get().isAdmin === "true" && (
                        <Tab eventKey="assign-role" title="Assign Role to User">
                          <AssignRoleForm />
                        </Tab>
                      )}
                      {user.get().isAdmin === "true" && (
                        <Tab eventKey="reg-shop" title="Register Shop">
                          <RegShopForm />
                        </Tab>
                      )}
                      {user.get().isAdmin === "true" && (
                        <Tab eventKey="user-unit" title="Check User Unit">
                          <UserDetailsForm />
                        </Tab>
                      )}
                    </Tabs>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Row className="pt-5 ml-n5 mr-n4 pl-5 pr-5">
              {user.get().isAdmin === "true" ||
              user.get().isAdmin === "Bet9ja" ? (
                <>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Line
                        data={bet9jaLineChartData}
                        options={lineChartOptions}
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Bar
                        data={bet9jaBarChartData}
                        options={barChartOptions}
                      />
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}
              {user.get().isAdmin === "true" ||
              user.get().isAdmin === "Betking" ? (
                <>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Line
                        data={betkingLineChartData}
                        options={lineChartOptions}
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Bar
                        data={betkingBarChartData}
                        options={barChartOptions}
                      />
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}
              {user.get().isAdmin === "true" ||
              user.get().isAdmin === "Sportybet" ? (
                <>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Line
                        data={sportybetLineChartData}
                        options={lineChartOptions}
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Bar
                        data={sportybetBarChartData}
                        options={barChartOptions}
                      />
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}
              {user.get().isAdmin === "true" ||
              user.get().isAdmin === "Bet22" ? (
                <>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Line
                        data={bet22LineChartData}
                        options={lineChartOptions}
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Bar data={bet22BarChartData} options={barChartOptions} />
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}
              {user.get().isAdmin === "true" ||
              user.get().isAdmin === "Bet1x" ? (
                <>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Line
                        data={bet1xLineChartData}
                        options={lineChartOptions}
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Bar data={bet1xBarChartData} options={barChartOptions} />
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}
              {user.get().isAdmin === "true" ||
              user.get().isAdmin === "Betwinner" ? (
                <>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Line
                        data={betwinnerLineChartData}
                        options={lineChartOptions}
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Bar
                        data={betwinnerBarChartData}
                        options={barChartOptions}
                      />
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}
              {user.get().isAdmin === "true" ||
              user.get().isAdmin === "Melbet" ? (
                <>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Line
                        data={melbetLineChartData}
                        options={lineChartOptions}
                      />
                    </div>
                  </Col>
                  <Col lg={6} className="mb-5">
                    <div className="admin-post">
                      <Bar
                        data={melbetBarChartData}
                        options={barChartOptions}
                      />
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
            <Row className="pt-5 ml-n5 mr-n4 pl-5 pr-5">
              <Col lg={12}>
                {/* <SubscriptionPlanTable /> */}
              </Col>
            </Row>
          </Container>
          <div className="mobile-wrapper">
            <div className="mobile-cards mt-3">
              <DashboardCard
                title="Available Conversion Units"
                value={conversionUnit.get()}
                icon="ci:refresh-02"
              />
              <DashboardCard
                title="Total Conversions done"
                value={totalConversions.get()}
                icon="bi:patch-check-fill"
              />
              <DashboardCard
                title="Current Plan"
                value={conversionPlan.get()}
                icon="icon-park-outline:tag"
              />
            </div>
            {user.get().isAdmin === "true" ||
            user.get().isAdmin === "editor" ? (
              <div className="admin-post mt-3 mb-2">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                >
                  <Tab eventKey="match-review" title="Match Review">
                    <MatchReviewForm />
                  </Tab>
                  <Tab eventKey="punters-tips" title="Punters Tips">
                    <PuntersTipsForm />
                  </Tab>
                  <Tab eventKey="bet-term" title="Bet Terminology">
                    <BetTermForm />
                  </Tab>
                  <Tab eventKey="available-league" title="Available League">
                    <AvailableLeagueForm />
                  </Tab>
                  <Tab eventKey="available-option" title="Available Option">
                    <AvailableOptionForm />
                  </Tab>
                  <Tab eventKey="stat" title="Stat">
                    <StatForm />
                  </Tab>
                  {user.get().isAdmin === "true" && (
                    <Tab eventKey="credit-unit" title="Credit Unit(Manual)">
                      <CreditUnitForm />
                    </Tab>
                  )}
                  {user.get().isAdmin === "true" && (
                    <Tab eventKey="assign-role" title="Assign Role to User">
                      <AssignRoleForm />
                    </Tab>
                  )}
                  {user.get().isAdmin === "true" && (
                    <Tab eventKey="reg-shop" title="Register Shop">
                      <RegShopForm />
                    </Tab>
                  )}
                  {user.get().isAdmin === "true" && (
                    <Tab eventKey="user-unit" title="Check User Unit">
                      <UserDetailsForm />
                    </Tab>
                  )}
                </Tabs>
              </div>
            ) : (
              ""
            )}

            {user.get().isAdmin === "true" ||
            user.get().isAdmin === "Bet9ja" ? (
              <>
                <div className="admin-post mt-3 mb-2">
                  <Line data={bet9jaLineChartData} options={lineChartOptions} />
                </div>
                <div className="admin-post mt-3 mb-2">
                  <Bar data={bet9jaBarChartData} options={barChartOptions} />
                </div>
              </>
            ) : (
              ""
            )}
            {user.get().isAdmin === "true" ||
            user.get().isAdmin === "Betking" ? (
              <>
                <div className="admin-post mt-3 mb-2">
                  <Line
                    data={betkingLineChartData}
                    options={lineChartOptions}
                  />
                </div>
                <div className="admin-post mt-3 mb-2">
                  <Bar data={betkingBarChartData} options={barChartOptions} />
                </div>
              </>
            ) : (
              ""
            )}
            {user.get().isAdmin === "true" ||
            user.get().isAdmin === "Sportybet" ? (
              <>
                <div className="admin-post mt-3 mb-2">
                  <Line
                    data={sportybetLineChartData}
                    options={lineChartOptions}
                  />
                </div>
                <div className="admin-post mt-3 mb-2">
                  <Bar data={sportybetBarChartData} options={barChartOptions} />
                </div>
              </>
            ) : (
              ""
            )}
            {user.get().isAdmin === "true" || user.get().isAdmin === "22Bet" ? (
              <>
                <div className="admin-post mt-3 mb-2">
                  <Line data={bet22LineChartData} options={lineChartOptions} />
                </div>
                <div className="admin-post mt-3 mb-2">
                  <Bar data={bet22BarChartData} options={barChartOptions} />
                </div>
              </>
            ) : (
              ""
            )}
            {user.get().isAdmin === "true" || user.get().isAdmin === "1xBet" ? (
              <>
                <div className="admin-post mt-3 mb-2">
                  <Line data={bet1xLineChartData} options={lineChartOptions} />
                </div>
                <div className="admin-post mt-3 mb-2">
                  <Bar data={bet1xBarChartData} options={barChartOptions} />
                </div>
              </>
            ) : (
              ""
            )}
            {user.get().isAdmin === "true" ||
            user.get().isAdmin === "Betwinner" ? (
              <>
                <div className="admin-post mt-3 mb-2">
                  <Line
                    data={betwinnerLineChartData}
                    options={lineChartOptions}
                  />
                </div>
                <div className="admin-post mt-3 mb-2">
                  <Bar data={betwinnerBarChartData} options={barChartOptions} />
                </div>
              </>
            ) : (
              ""
            )}
            {user.get().isAdmin === "true" ||
            user.get().isAdmin === "Melbet" ? (
              <>
                <div className="admin-post mt-3 mb-2">
                  <Line data={melbetLineChartData} options={lineChartOptions} />
                </div>
                <div className="admin-post mt-3 mb-2">
                  <Bar data={melbetBarChartData} options={barChartOptions} />
                </div>
              </>
            ) : (
              ""
            )}
            <div className="sub-plans mt-3">
              <SubscriptionPlanTable />
            </div>
          </div>
        </>
      )}
    </Frame>
  );
};

export default Dashboard;
