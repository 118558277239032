import { http } from './httpService';


export const getSavedCodes = () => {
    return http.get("/tp-code")
}

export const checkIfCodeExist = (data) => {
    return http.post("/tp-code/checker", data)
}

export const saveCode = (data) => {
    return http.post("/tp-code/create", data)
}

export const converterTP = (data) => {
    return http.post("/tp-code/convert", data)
}