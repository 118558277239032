export const bookies = [
        {bookie: "1xbet", from: "1", to: "1", name: "1xbet ", img: "1xbet.webp"},
        {bookie: "1xbet:gh", from: "1", to: "1", name: "1xbet -Ghana", img: "1xbet.webp"},
        {bookie: "1xbet:ke", from: "1", to: "1", name: "1xbet -Kenya", img: "1xbet.webp"},
        {bookie: "1xbet:ng", from: "1", to: "1", name: "1xbet -Nigeria", img: "1xbet.webp"},
        {bookie: "1xbet:ug", from: "1", to: "1", name: "1xbet -Uganda", img: "1xbet.webp"},
        {bookie: "1xbit", from: "1", to: "1", name: "1xbit ", img: "1xbit.png"},
        {bookie: "22bet", from: "1", to: "1", name: "22bet ", img: "22bet.webp"},
        {bookie: "bangbet", from: "1", to: "1", name: "bangbet ", img: "bangbet.png"},
        {bookie: "bet9ja", from: "1", to: "1", name: "bet9ja ", img: "bet9ja.webp"},
        {bookie: "betandyou", from: "1", to: "1", name: "betandyou "},
        {bookie: "betika:gh", from: "1", to: "1", name: "betika -Ghana", img: "betika.png"},
        {bookie: "betika:ke", from: "1", to: "1", name: "betika -Kenya", img: "betika.png"},
        {bookie: "betika:ng", from: "1", to: "1", name: "betika -Nigeria", img: "betika.png"},
        {bookie: "betking:ke", from: "1", to: "1", name: "betking -Kenya", img: "betking.png"},
        {bookie: "betking:ng", from: "1", to: "1", name: "betking -Nigeria", img: "betking.png"},
        {bookie: "betway:gh", from: "1", to: "0", name: "betway -Ghana", img: "betway.png"},
        {bookie: "betway:ke", from: "1", to: "0", name: "betway -Kenya", img: "betway.png"},
        {bookie: "betway:ng", from: "1", to: "0", name: "betway -Nigeria", img: "betway.png"},
        {bookie: "betway:tz", from: "1", to: "0", name: "betway -Tanzania", img: "betway.png"},
        {bookie: "betway:ug", from: "1", to: "0", name: "betway -Uganda", img: "betway.png"},
        {bookie: "betway:za", from: "1", to: "0", name: "betway -South Africa", img: "betway.png"},
        {bookie: "betwinner:ng", from: "1", to: "1", name: "betwinner -Nigeria", img: "betwinner.png"},
        {bookie: "double_bet", from: "1", to: "1", name: "double bet "},
        {bookie: "helabet", from: "1", to: "1", name: "helabet "},
        {bookie: "linebet", from: "1", to: "1", name: "linebet "},
        {bookie: "livescorebet:ng", from: "1", to: "0", name: "livescorebet -Nigeria", img: "livescorebet.png"},
        {bookie: "megapari", from: "1", to: "1", name: "megapari "},
        {bookie: "melbet", from: "1", to: "1", name: "melbet ", img: "melbet.webp"},
        {bookie: "msport", from: "1", to: "1", name: "msport ", img: "msport.png"},
        {bookie: "nairabet", from: "1", to: "1", name: "nairabet ", img: "nairabet.png"},
        {bookie: "odibet:gh", from: "1", to: "1", name: "odibet -Ghana", img: "odibet.png"},
        {bookie: "odibet:ke", from: "1", to: "1", name: "odibet -Kenya", img: "odibet.png"},
        {bookie: "paripesa", from: "1", to: "1", name: "paripesa ", img: "paripesa.png"},
        {bookie: "sportybet:gh", from: "1", to: "1", name: "sportybet -Ghana", img: "sportybet.png"},
        {bookie: "sportybet:ke", from: "1", to: "1", name: "sportybet -Kenya", img: "sportybet.png"},
        {bookie: "sportybet:ng", from: "1", to: "1", name: "sportybet -Nigeria", img: "sportybet.png"},
        {bookie: "sportybet:tz", from: "1", to: "1", name: "sportybet -Tanzania", img: "sportybet.png"},
        {bookie: "sportybet:ug", from: "1", to: "1", name: "sportybet -Uganda", img: "sportybet.png"},
        {bookie: "sportybet:zm", from: "1", to: "1", name: "sportybet -Zambia", img: "sportybet.png"}
]